export function formatPrice(price, cents = false) {
    return `${(cents ? price / 100 : price).toLocaleString('ru-RU')} Р`;
}
export function formatAmount(amount, forms = ['день', 'дня', 'дней']) {
    return [amount, pluralize(amount, forms)].join(' ');
}
export function percentFrom(dividend, divider) {
    const res = (dividend / divider * 100);
    return res ? `${res.toFixed(2)}%` : '—';
}
export function truncateString(input, limit) {
    if (input.length <= limit)
        return input;
    return input.slice(0, limit) + '...';
}
function pluralize(n, [singular, genitiveSingular, genitivePlural]) {
    n = Math.abs(n); // Для обработки отрицательных чисел
    if (n % 10 === 1 && n % 100 !== 11) {
        return singular;
    }
    else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
        return genitiveSingular;
    }
    else {
        return genitivePlural;
    }
}
