import { h, Component } from 'preact';
import { FrameModal } from 'site/VDOMComponents/Modals/FrameModal';
export class VideoPlayerModal extends Component {
    constructor() {
        super(...arguments);
        this.renderEmbedPlayer = () => {
            const { externalVideoId, externalVideoType } = this.props;
            if (!externalVideoId)
                return;
            switch (externalVideoType) {
                case 'YoutubeVideo':
                    return (h("iframe", { type: 'text/html', width: Env.version === 'desktop' ? '640' : '340', height: Env.version === 'desktop' ? '360' : '200', src: `https://www.youtube.com/embed/${externalVideoId}?enablejsapi=1&origin=${window.location.origin}`, frameBorder: '0' }));
                case 'RutubeVideo':
                    return (h("iframe", { type: 'text/html', width: Env.version === 'desktop' ? '640' : '340', height: Env.version === 'desktop' ? '360' : '200', src: `https://rutube.ru/play/embed/${externalVideoId}`, frameBorder: '0' }));
                default:
                    return;
            }
        };
    }
    render() {
        const { videoFileUrl, onClose } = this.props;
        return (h(FrameModal, { contentClassName: 'b-VideoPlayerModal', onClose: onClose }, videoFileUrl ? (h("video", { src: videoFileUrl, controls: true })) : this.renderEmbedPlayer() || 'Нет доступных видео для отображения'));
    }
}
