var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h, render } from 'preact';
import { Controller, register } from 'core/services/Controller';
import { SHOW_PREVIEW_EVENT, HIDE_PREVIEW_EVENT, buildEventName } from 'site/VControllers/Products/ProductVariants';
import { index as indexFromItem } from 'core/utils/DOMHelper';
import { VideoPlayerModal } from 'site/modals/VideoPlayerModal';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.scrollContainer = this.findElement(`%{c-ProductGallery/scrollContainer}`);
        this.thumbsContainer = this.findElement('%{c-ProductGallery/thumbs}');
        this.thumbPhotos = this.findElements('%{c-ProductGallery/thumbs/photo}');
        this.variantPreviewPhoto = this.findElement('%{c-ProductGallery/anotherProductPreview}');
        this.currentPhotoId = this.thumbPhotos[0].dataset.photoId;
        this.currentPhotoIndex = 0;
        this.hasNext = true;
        this.hasPrev = false;
        this.observer = new IntersectionObserver(entries => {
            entries
                .filter(({ isIntersecting }) => isIntersecting)
                .forEach(({ target }) => {
                this.setCurrentPhoto(indexFromItem(target));
            });
        }, { threshold: 0.5 });
        this.handleShowAnotherProductPreview = ({ detail }) => {
            this.variantPreviewPhoto.style.backgroundImage = `url(${detail.imagePreview})`;
            this.variantPreviewPhoto.classList.add('__shown');
        };
        this.handleHideAnotherProductPreview = () => {
            this.variantPreviewPhoto.classList.remove('__shown');
            this.variantPreviewPhoto.style.backgroundImage = '';
        };
        this.handleSelectThumb = (e) => {
            if (!e.target)
                return;
            let index = indexFromItem(e.target);
            this.setCurrentPhoto(index);
            this.scrollContainer.scrollTo({ left: this.itemWidth * index, top: 0, behavior: 'smooth' });
        };
        this.setCurrentPhoto = (index) => {
            this.currentPhotoId = this.thumbPhotos[index].dataset.photoId;
            this.hasNext = index < this.props.photosCount - 1;
            this.hasPrev = index > 0;
            this.currentPhotoIndex = index;
            this.handleThumbActive('%{c-ProductGallery/thumbs/photo}', index);
        };
        this.handleNextPhoto = () => {
            let index = this.hasNext ? this.currentPhotoIndex + 1 : 0;
            this.setCurrentPhoto(index);
            this.scrollContainer.scrollTo({ left: this.itemWidth * index, top: 0, behavior: 'smooth' });
        };
        this.handlePrevPhoto = () => {
            let index = this.hasPrev ? this.currentPhotoIndex - 1 : this.props.photosCount - 1;
            this.setCurrentPhoto(index);
            this.scrollContainer.scrollTo({ left: this.itemWidth * index, top: 0, behavior: 'smooth' });
        };
        this.handleThumbActive = (selector, activeIndex) => {
            this.findElements(selector).forEach((el, index) => {
                el.classList.toggle('__isActive', index === activeIndex);
                if (index === activeIndex) {
                    const { left: itemLeft, right: itemRight } = el.getBoundingClientRect();
                    const { left: containerLeft, right: containerRight } = this.thumbsContainer.getBoundingClientRect();
                    if (containerRight <= itemRight + this.itemThumbWidth) {
                        this.thumbsContainer.scrollTo({ left: this.thumbsContainer.clientWidth, top: 0, behavior: 'smooth' });
                    }
                    else if (containerLeft >= itemLeft - this.itemThumbWidth) {
                        this.thumbsContainer.scrollTo({ left: -this.thumbsContainer.clientWidth, top: 0, behavior: 'smooth' });
                    }
                }
            });
        };
        this.openPhotoModal = async () => {
            if (!this.props.hasZoom)
                return;
            const { PhotoModal, PhotoListCursor } = await import(
            /* webpackChunkName: 'common__s-PhotoModal'*/
            //@ts-ignore
            'core/services/PhotoModal');
            const photoIds = this.findElements('%{c-ProductGallery/thumbs/photo}').map(el => el.dataset.photoId);
            const photoId = this.currentPhotoId;
            const photoListCursor = new PhotoListCursor({
                list: () => photoIds
            }).moveTo(photoId);
            PhotoModal.open(photoListCursor, { hideTagging: true });
        };
        this.openVideoModal = (e) => {
            const payload = e.target.dataset.videoPayload;
            if (!payload)
                return;
            const { externalVideoId, externalVideoType, videoFileUrl } = JSON.parse(payload);
            this.videoModal = render((h(VideoPlayerModal, { onClose: this.closeVideoModal, externalVideoId: externalVideoId, externalVideoType: externalVideoType, videoFileUrl: videoFileUrl })), document.body);
        };
        this.closeVideoModal = () => {
            render(null, document.body, this.videoModal);
        };
    }
    init() {
        super.init();
        [...this.scrollContainer.children].forEach(item => {
            this.observer.observe(item);
        });
    }
    destroy() {
        super.destroy();
        [...this.scrollContainer.children].forEach(item => {
            this.observer.unobserve(item);
        });
    }
    get nativeListeners() {
        const DEFAULT_LISTENERS = [
            { selector: 'document', event: this.buildEventName(SHOW_PREVIEW_EVENT), handler: this.handleShowAnotherProductPreview },
            { selector: 'document', event: this.buildEventName(HIDE_PREVIEW_EVENT), handler: this.handleHideAnotherProductPreview },
            { selector: '%{c-ProductGallery/zoom}', event: 'click', handler: this.openPhotoModal },
            { selector: '%{c-ProductGallery/video}', event: 'click', handler: this.openVideoModal }
        ];
        if (this.props.onePhoto)
            return DEFAULT_LISTENERS;
        return [
            ...DEFAULT_LISTENERS,
            { selector: '%{c-ProductGallery/next}', event: 'click', handler: this.handleNextPhoto },
            { selector: '%{c-ProductGallery/prev}', event: 'click', handler: this.handlePrevPhoto },
            { selector: '%{c-ProductGallery/thumbs/photo}', event: 'click', handler: this.handleSelectThumb }
        ];
    }
    get itemWidth() {
        var _a, _b;
        return (_b = (_a = this.scrollContainer.firstElementChild) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0;
    }
    get itemThumbWidth() {
        var _a, _b;
        return (_b = (_a = this.thumbsContainer.firstElementChild) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0;
    }
    buildEventName(event) {
        return buildEventName(event, this.props.viewType);
    }
};
default_1 = __decorate([
    register('ScrollableProductGallery')
], default_1);
export default default_1;
