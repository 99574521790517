const DAYS = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
const buildScheduleTest = (schedule) => {
    const daysMap = {};
    schedule.restrictions.forEach(({ days, time_from, time_to }) => {
        const timeRange = `${formatTime(time_from)}-${formatTime(time_to)}`;
        if (!daysMap[timeRange]) {
            daysMap[timeRange] = [];
        }
        daysMap[timeRange].push(...days);
    });
    const formattedSchedule = Object.entries(daysMap).map(([timeRange, days]) => {
        const groupedDays = groupDays(days);
        return `<span>${groupedDays}: ${timeRange}</span>`;
    });
    return formattedSchedule.join('<br>');
};
const formatTime = (time) => {
    const hours = time.hours.toString().padStart(2, '0');
    const minutes = time.minutes.toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};
const groupDays = (days) => {
    const sortedDays = days.sort((a, b) => a - b);
    const ranges = [];
    let rangeStart = sortedDays[0];
    let rangeEnd = sortedDays[0];
    for (let i = 1; i < sortedDays.length; i++) {
        const currentDay = sortedDays[i];
        const previousDay = sortedDays[i - 1];
        if (currentDay === previousDay + 1) {
            rangeEnd = currentDay;
        }
        else {
            ranges.push(rangeStart === rangeEnd ? DAYS[rangeStart - 1] : `${DAYS[rangeStart - 1]}-${DAYS[rangeEnd - 1]}`);
            rangeStart = currentDay;
            rangeEnd = currentDay;
        }
    }
    ranges.push(rangeStart === rangeEnd ? DAYS[rangeStart - 1] : `${DAYS[rangeStart - 1]}-${DAYS[rangeEnd - 1]}`);
    return ranges.join(', ');
};
export default function getData(points) {
    return {
        type: 'FeatureCollection',
        features: points.map(point => {
            return {
                type: 'Feature',
                id: point.id,
                address: {
                    fullAddress: point.address.full_address
                },
                geometry: {
                    type: 'Point',
                    coordinates: [point.position.latitude, point.position.longitude]
                },
                properties: {
                    pickupPointId: point.id,
                    fullAddress: point.address.full_address,
                    balloonContentHeader: `<h4>${point.name}</h4>`,
                    balloonContentBody: `<p>Адрес: ${point.address.full_address}<p><p>График работы:</p>${buildScheduleTest(point.schedule)}`,
                    clusterCaption: `<p>${point.name}</p>`
                }
            };
        })
    };
}
