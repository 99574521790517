var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { Http } from 'core/services/HttpClient';
const OPEN_CLASS_NAME = '__open';
const ACTIVE_CLASS_NAME = '__active';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.isOpen = false;
        this.isRendered = false;
        this.rootItems = [];
        this.rootItemsChildren = [];
        this.dropdownContainer = this.findElement('%{HeaderAllCategories/dropdownContainer}');
        this.sectionItems = [];
        this.sections = [];
        this.handleOutsideClick = (e) => {
            const target = e.target;
            if (!this.el.contains(target))
                this.close();
        };
        this.handleToggle = () => {
            this.isOpen ? this.close() : this.open();
        };
        this.handleHoverRootItem = (e) => {
            const el = e.target;
            this.setActiveCategory(parseInt(el.dataset.id));
        };
        this.handleHoverSectionItem = (e) => {
            const el = e.target;
            this.setActiveSection(el.dataset.id);
        };
    }
    get nativeListeners() {
        return [
            { selector: `%{HeaderAllCategories/toggler}`, event: 'click', handler: this.handleToggle }
        ];
    }
    open() {
        if (this.isOpen)
            return;
        this.render();
        this.el.classList.add(OPEN_CLASS_NAME);
        this.isOpen = true;
        requestAnimationFrame(() => {
            document.addEventListener('click', this.handleOutsideClick);
        });
    }
    close() {
        if (!this.isOpen)
            return;
        document.removeEventListener('click', this.handleOutsideClick);
        this.el.classList.remove(OPEN_CLASS_NAME);
        this.isOpen = false;
    }
    setActiveCategory(categoryId) {
        if (this.activeCategoryId === categoryId)
            return;
        this.activeCategoryId = categoryId;
        this.rootItems.forEach((rootItem) => {
            rootItem.classList.toggle(ACTIVE_CLASS_NAME, parseInt(rootItem.dataset.id) === this.activeCategoryId);
        });
        this.rootItemsChildren.forEach((rootChildren) => {
            rootChildren.classList.toggle(ACTIVE_CLASS_NAME, parseInt(rootChildren.dataset.categoryId) === this.activeCategoryId);
        });
    }
    setActiveSection(sectionId) {
        if (!sectionId)
            return;
        if (this.activeSectionId === sectionId)
            return;
        this.activeSectionId = sectionId;
        this.sectionItems.forEach((sectionItem) => {
            sectionItem.classList.toggle(ACTIVE_CLASS_NAME, sectionItem.dataset.id === this.activeSectionId);
        });
        this.sections.forEach((section) => {
            section.classList.toggle(ACTIVE_CLASS_NAME, section.dataset.id === this.activeSectionId);
        });
    }
    async render() {
        var _a;
        if (this.isRendered)
            return;
        const dropdown = this.createDropdown();
        this.dropdownContainer.append(dropdown);
        const content = await this.createContent();
        (_a = dropdown.firstElementChild) === null || _a === void 0 ? void 0 : _a.replaceWith(content);
        this.updateRootItemsList();
        this.setActiveCategory(this.props.activeId);
        if (this.props.allSections) {
            this.setSectionsHandlers();
        }
        this.isRendered = true;
    }
    createDropdown() {
        const dropdown = document.createElement('div');
        dropdown.classList.add('s-HeaderNavDropdown', '__fullWidth');
        dropdown.innerHTML = `
        <div class="b-AjaxLoader">
          <div></div>
          <div></div>
          <div></div>
        </div>
    `;
        return dropdown;
    }
    async createContent() {
        var _a;
        const { currentPageName, allSections } = this.props;
        const template = document.createElement('template');
        let queryParams = { page: currentPageName, withCityId: (_a = Env.currentCity) === null || _a === void 0 ? void 0 : _a.id };
        if (allSections) {
            queryParams = { all_sections: allSections, ...queryParams };
        }
        const navResponse = await Http.get('/front_api/navigation/subnav', { params: queryParams });
        template.innerHTML = navResponse.data;
        return template.content;
    }
    updateRootItemsList() {
        this.rootItems = this.findElements('%{HeaderAllCategories/item}');
        this.rootItemsChildren = this.findElements('%{HeaderAllCategories/rootItemChildren}');
        this.rootItems.forEach((rootItem) => {
            rootItem.addEventListener('mouseenter', this.handleHoverRootItem);
        });
    }
    setSectionsHandlers() {
        this.sectionItems = this.findElements('%{HeaderAllCategories/sectionItem}');
        this.sections = this.findElements('%{HeaderAllCategories/section}');
        this.sectionItems.forEach((sectionItem) => {
            sectionItem.addEventListener('mouseenter', this.handleHoverSectionItem);
            if (this.props.currentPageName === sectionItem.dataset.id) {
                this.setActiveSection(sectionItem.dataset.id);
            }
        });
    }
};
default_1 = __decorate([
    register('HeaderAllCategoriesLazy')
], default_1);
export default default_1;
