import { Component } from 'preact';
import h from './cssScope';
import { toRub } from './utils/humanize';
export class App extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            loading: true,
            installmentChanged: false,
            selectedQuantity: 1,
            cartTotalPrice: 0,
            paymentMethod: 'online',
            invalidInstallment: null
        };
        this.handleLoading = async () => {
            const { default: checkoutContext } = await import(
            /* webpackChunkName: 'common__c-checkoutContext' */
            'site/VControllers/components/Checkout/context');
            this.checkoutContext = checkoutContext;
            await this.checkoutContext.init();
            if (this.props.data) {
                this.checkoutContextUuid = await this.checkoutContext.subscribe(this.handleUpdate);
                this.handleUpdate();
            }
            this.setState({
                loading: false,
                cartTotalPrice: this.cartTotalPrice,
                paymentMethod: this.currentPaymentMethod
            });
        };
        this.handleUpdate = () => {
            const productInCart = this.getProductInCart;
            const select = document.querySelector('.b-QuantityActions_actionCount');
            const addToCardButtons = document.querySelectorAll('.__addToCard');
            if (!productInCart) {
                select === null || select === void 0 ? void 0 : select.addEventListener('change', this.handleSelectQuantityChange);
                addToCardButtons.forEach(button => {
                    button.addEventListener('click', this.handleSelectQuantityChange);
                });
                this.setState({
                    cartTotalPrice: this.cartTotalPrice,
                    paymentMethod: this.currentPaymentMethod
                });
                return;
            }
        };
        this.handleSelectQuantityChange = (e) => {
            const target = e.target;
            const value = target.value;
            this.setState({
                selectedQuantity: Number(value),
                cartTotalPrice: this.cartTotalPrice,
                paymentMethod: this.currentPaymentMethod,
                invalidInstallment: null
            });
        };
    }
    componentWillMount() {
        this.handleLoading();
    }
    get validData() {
        const { productPrice } = this.props;
        const { selectedQuantity, cartTotalPrice } = this.state;
        const currentProductPrice = productPrice * selectedQuantity;
        const cartTotalPriceWithProduct = this.state.invalidInstallment
            ? cartTotalPrice
            : cartTotalPrice + currentProductPrice;
        const resultPrice = this.getProductInCart
            ? cartTotalPrice
            : cartTotalPriceWithProduct;
        return this.props.data.filter(installment => resultPrice >= installment.limitMin &&
            resultPrice <= installment.limitMax);
    }
    get getProductInCart() {
        var _a;
        return (_a = this.checkoutContext) === null || _a === void 0 ? void 0 : _a.getContext().store.getState().items.find(item => item.productId === this.props.productId);
    }
    get cartPaymentMethod() {
        var _a;
        return (_a = this.checkoutContext) === null || _a === void 0 ? void 0 : _a.getContext().store.getState().formFields.find(field => field.key === 'payment_method');
    }
    get currentPaymentMethod() {
        const validInsallment = this.validData[0];
        const defaultPaymentMethod = validInsallment
            ? validInsallment.paymentMethod
            : 'online';
        const paymentMethod = this.cartPaymentMethod
            ? this.cartPaymentMethod.value.toString()
            : defaultPaymentMethod;
        return paymentMethod.includes('installment')
            ? paymentMethod
            : defaultPaymentMethod;
    }
    get cartTotalPrice() {
        var _a;
        return (_a = this.checkoutContext) === null || _a === void 0 ? void 0 : _a.getContext().store.getState().totalPriceInfo.totalPrice;
    }
    getInstallment(paymentMethod) {
        const data = this.validData;
        const installment = data.find((installmentData) => installmentData.paymentMethod === paymentMethod);
        return installment ? installment : data[0];
    }
    render() {
        const { loading } = this.state;
        const { data, productPrice } = this.props;
        const price = Number(productPrice
            .toString()
            .replace(/\s/g, '')
            .replace(/₽/g, ''));
        const cartTotalPrice = this.cartTotalPrice;
        let company = null;
        if (cartTotalPrice === undefined)
            return '';
        if (price + cartTotalPrice < 30000) {
            company = data.find(item => item.name === 'Подели');
        }
        else if (price + cartTotalPrice >= 30000 &&
            price + cartTotalPrice < 100000) {
            company = data.find(item => item.name === 'Плайт');
        }
        else if (price + cartTotalPrice >= 100000 &&
            price + cartTotalPrice <= 200000) {
            company = data.find(item => item.name === 'Т-Банк');
        }
        else
            return null;
        if (loading || !data.length)
            return null;
        if (!company)
            return null;
        return (h("div", { className: 'b-InstallmentsWidget' },
            h("div", { className: `b-Logo ${(company === null || company === void 0 ? void 0 : company.name) === 'Подели'
                    ? ' _podeli'
                    : (company === null || company === void 0 ? void 0 : company.name) === 'Плайт'
                        ? '_plait'
                        : '_tbank'}  `, alt: company === null || company === void 0 ? void 0 : company.name }),
            h("div", { className: 'b-Title' },
                h("span", { className: 'b-Price' },
                    toRub.format(Number(company === null || company === void 0 ? void 0 : company.price)),
                    "\u00A0"),
                `в ${company === null || company === void 0 ? void 0 : company.name}`)));
    }
}
