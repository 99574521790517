var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createScope } from 'core/services/VDOM';
import { VController, register } from 'core/services/Controller';
import { DeliveryInfoFetcher } from './fetcher';
import htmlClassNames from 'core/utils/htmlClassNames';
const h = createScope('ProductPreview');
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.handleRequest = ({ text, type }) => {
            this.text = text;
            this.type = type;
            this.renderer.render();
        };
    }
    init() {
        DeliveryInfoFetcher.fetch(this.props.productId).then(this.handleRequest);
    }
    // get bgIcon() {
    //   return `<svg viewBox='0 0 6 40' aria-hidden='true'>
    //             <path d='M1.978 0C4.629 0 6.54699 2.532 5.82899 5.08384L1.92799 18.9412C1.73299 19.6342 1.72799 20.367 1.91499 21.0623L5.647 34.9627C6.33 37.5037 4.415 40 1.784 40H0V0H1.978Z' fill='var(--bg-color, white)' />
    //           </svg>`
    // }
    render() {
        const classNames = htmlClassNames('sb-Delivery __v2', { [`__${this.type}`]: !!this.type });
        const html = this.type ? this.text
            //  + this.bgIcon 
            : this.text;
        return (h("div", { className: classNames },
            h("div", { className: 'sb-Delivery_content', dangerouslySetInnerHTML: { __html: html } })));
    }
};
default_1 = __decorate([
    register('DeliveryInfo')
], default_1);
export default default_1;
