import Tooltip from 'core/components/UI/Tooltip';
import htmlClassNames from 'core/utils/htmlClassNames';
import { Component } from 'preact';
import { GtmAddressEvents } from 'site/VControllers/components/Checkout/constants';
import { OPERATION_CHANGE_GTM_ADDRESS_EVENT } from 'site/VControllers/components/Checkout/operations/gtm';
import { PART_SUBSTITUTIONS } from 'site/VControllers/SinglePageCheckout/services/fetchAddresses';
import { FieldsKeys } from '../../..';
import h from '../../../cssScope';
import { AddressSuggestions } from 'luxury_theme.mobile/components/AddressSuggestions';
import { ContentType } from 'site/VControllers/SinglePageCheckout/constants';
export class Settlement extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            inputRef: undefined,
            currentValue: undefined
        };
        this.awaitCityId = null;
        this.awaitImproveAddress = false;
        this.handleInputRef = (el) => {
            this.setState({ inputRef: el });
        };
        // to allow commit input address
        this.handleChangeSearch = (event) => {
            const value = event.target.value;
            this.awaitImproveAddress = true; // TODO: not needed now?
            this.props.onChange(value);
        };
        this.saveSearchValue = (input) => {
            const inputRef = this.state.inputRef || this.virtualInputRef;
            if (!inputRef)
                return;
            inputRef.value = input;
            // we need to trigger update state from native event. to show user changed information
            // Current architecture of baseAutocomplete doesn't allow to pass searchValue from parent component
            inputRef.dispatchEvent(new Event('input'));
        };
        this.handleSuggestionSelect = (suggestion) => {
            var _a, _b, _c, _d;
            this.setState({ currentValue: suggestion });
            if (!suggestion)
                return;
            (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.operate(OPERATION_CHANGE_GTM_ADDRESS_EVENT, GtmAddressEvents.AutocompleteAddress);
            const itemId = buildDaData(suggestion);
            this.awaitCityId = null;
            this.props.onChange(suggestion.value);
            if (itemId) {
                (_d = (_c = this.props).onChangeValue) === null || _d === void 0 ? void 0 : _d.call(_c, FieldsKeys.City, itemId);
            }
        };
        this.onFetchSuggestions = (suggestions) => {
            const allowedSettlementTypes = [null, 'деревня', 'село', 'поселок', 'станица', 'хутор', 'аул', 'станция', 'поселок городского типа', 'местечко', 'слобода'];
            return suggestions
                .filter((suggestion) => allowedSettlementTypes.includes(suggestion.data.settlementTypeFull))
                .filter((suggestion) => suggestion.data.cityDistrict === null)
                .slice(0, 6);
        };
    }
    field(key, props = this.props) {
        return props.formFields.find(v => v.key === key);
    }
    get isCountryBlank() {
        const countryField = this.field(FieldsKeys.Country);
        if (!countryField)
            return false;
        return !countryField.value;
    }
    componentDidUpdate(prevProps) {
        var _a, _b, _c, _d, _e;
        // NOTE: пришлось написать эту хрень и добавить стейте currentValue для одной цели,
        // чтобы при смене города извне (например по клику карты)
        // менялось и значение в автокомпите в этом компоненте
        const cityKladraId = (_a = this.field(FieldsKeys.City)) === null || _a === void 0 ? void 0 : _a.value;
        if (cityKladraId !== ((_b = this.field(FieldsKeys.City, prevProps)) === null || _b === void 0 ? void 0 : _b.value)) {
            if (((_d = (_c = this.state.currentValue) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.kladrId) !== cityKladraId) {
                this.setState({
                    currentValue: {
                        value: (_e = this.field(FieldsKeys.Settlement)) === null || _e === void 0 ? void 0 : _e.value,
                        data: { kladrId: cityKladraId }
                    }
                });
            }
        }
    }
    render() {
        var _a;
        const { field, name, disabled, useHidden } = this.props;
        const { inputRef, currentValue } = this.state;
        const isMobile = Env.version === 'mobile';
        const countryField = this.field(FieldsKeys.Country);
        const countryText = (countryField === null || countryField === void 0 ? void 0 : countryField.value) ? (_a = (countryField.items.find((v) => v.id === countryField.value))) === null || _a === void 0 ? void 0 : _a.text : null;
        const filterLocations = [];
        if (countryText) {
            filterLocations.push({ country: countryText });
        }
        return (h("div", { className: 'ab-test' },
            h("div", { className: htmlClassNames('ui-Input') },
                useHidden && h("input", { ref: this.handleInputRef, name: name, type: 'text', value: field.value }),
                (!useHidden || inputRef) && (h(AddressSuggestions, { value: currentValue, token: Env.daData.token, containerClassName: 'ui-Input_autocomplete __autocompleteFixed __labelFloated', suggestionsClassName: 's-AutocompleteTree ui-Input_items __fixedList __open', suggestionClassName: 's-AutocompleteTree_b-Item __root __ab-test', currentSuggestionClassName: '__selected', highlightClassName: 's-AutocompleteTree_b-Highlighted', count: 20, customInput: isMobile ? 'textarea' : 'input', delay: 100, minChars: 1, httpCache: true, onChange: (suggestion) => this.handleSuggestionSelect(suggestion), onFetchSuggestions: this.onFetchSuggestions, filterFromBound: 'city', filterToBound: 'settlement', filterLocations: filterLocations, labelText: field.label, selectOnBlur: field.value ? false : true, inputProps: {
                        disabled: disabled || this.isCountryBlank,
                        onChange: (input) => this.handleChangeSearch(input)
                    }, defaultQuery: field.value.toString() })),
                this.isCountryBlank && h(Tooltip, { label: '\u0421\u043D\u0430\u0447\u0430\u043B\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0443' }))));
    }
}
const NAME_SUBSTITUTIONS = {
    'Саха /Якутия/': 'Саха (Якутия)'
};
function joinParts(parts) {
    return parts.reduce((acc, [type, name]) => {
        var _a;
        if (!(name || type))
            return acc;
        const humanType = type ? `${(_a = PART_SUBSTITUTIONS[type]) !== null && _a !== void 0 ? _a : `${type}.`} ` : '';
        const humanName = name ? NAME_SUBSTITUTIONS[name.toString()] || name : '';
        acc += `${acc ? ', ' : ''}${humanType}${humanName}`;
        return acc;
    }, '');
}
function buildDaData(suggestion) {
    if (!suggestion)
        return null;
    const data = suggestion.data;
    const { cityKladrId, settlementKladrId, settlementType } = data;
    const idSettlementValid = settlementType !== 'р-н';
    const parts = [[data.streetType, data.street]];
    const kladrId = settlementKladrId && idSettlementValid ? settlementKladrId : cityKladrId;
    const idParts = [[ContentType.City, kladrId]];
    const text = joinParts(parts);
    const id = idParts.reduce((acc, [type, id]) => id ? `${acc}${acc ? ':' : ''}${type}/${id}` : acc, '');
    if (text) {
        return id;
    }
    else {
        return kladrId;
    }
}
