import { formatAmount } from 'core/utils/humanize';
export function getInstallmentText(installment, quantity = 1) {
    const { price, paymentsPeriod, paymentsNumber } = installment;
    const currentPrice = (price * quantity).toLocaleString().replace(/,/g, ' ');
    switch (paymentsPeriod) {
        case 'half_month':
            return `${currentPrice} ₽ / ${formatAmount(paymentsNumber, ['платеж', 'платежа', 'платежей'])} раз в две недели`;
        case 'month':
            return `${currentPrice} ₽ / мес. на ${formatAmount(paymentsNumber, ['месяц', 'месяца', 'месяцев'])}`;
        default:
            throw new Error('Unknown paymentsPeriod');
    }
}
export function getOtherProgramsText(amount) {
    if (amount === 0) {
        return 'Посмотреть';
    }
    const humanizedAmount = formatAmount(amount, ['программа', 'программы', 'программ']);
    return `Еще ${humanizedAmount}`;
}
export const toRub = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0
});
