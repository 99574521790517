var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
import { dispatchCustomEvent } from 'core/utils/events';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.getGaWithFallBack = () => {
            let clientId;
            try {
                clientId = window.ga.getAll()[0].get('clientId');
            }
            catch (error) {
                clientId = '';
            }
            return clientId;
        };
        this.getUrlParams = (str) => {
            const hashes = str.slice(str.indexOf('?') + 1).split('&');
            if (hashes[0] === '')
                return {};
            return hashes.reduce((params, hash) => {
                const [key, val] = hash.split('=');
                return Object.assign(params, { [key]: val ? decodeURIComponent(val) : '' });
            }, {});
        };
        this.handleRequest = async (e) => {
            e.preventDefault();
            const { RequestDesignerPriceModalFlat } = await import(
            /* webpackChunkName: 'common__m-RequestDesignerPrice' */
            // @ts-ignore
            'site/modals/RequestDesignerPriceModalFlat');
            const modal = RequestDesignerPriceModalFlat({
                callbacks: {
                    CALLBACK_SUBMIT: async ({ firstName, lastName, comment, email, phone, site }) => {
                        const utmParams = this.getUrlParams(window.location.search);
                        const priceRequest = {
                            first_name: firstName,
                            last_name: lastName,
                            comment: comment,
                            email: email,
                            phone: phone,
                            site: site,
                            ...utmParams,
                            ga_client_id: this.getGaWithFallBack()
                        };
                        await Http.post(`/products/${this.props.productId}/request_designer_price`, {
                            data: {
                                price_request: priceRequest
                            }
                        });
                        modal.destroy();
                        dispatchCustomEvent(this.el, 'c-BtnRequestProductDesignerPrice:sended');
                    },
                    CALLBACK_CANCEL: () => {
                        modal.destroy();
                    }
                }
            });
        };
    }
    get nativeListeners() {
        return [
            { event: 'click', handler: this.handleRequest }
        ];
    }
};
default_1 = __decorate([
    register('BtnRequestProductDesignerPriceFlat')
], default_1);
export default default_1;
